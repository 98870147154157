/* noto-sans-kr-100 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-100.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
       url('./fonts/noto-sans-kr-v8-korean_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-300 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
       url('./fonts/noto-sans-kr-v8-korean_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
       url('./fonts/noto-sans-kr-v8-korean_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
       url('./fonts/noto-sans-kr-v8-korean_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
       url('./fonts/noto-sans-kr-v8-korean_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/noto-sans-kr-v8-korean_latin-900.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
       url('./fonts/noto-sans-kr-v8-korean_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/noto-sans-kr-v8-korean_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/noto-sans-kr-v8-korean_latin-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}